import React, { Component, PropTypes } from "react";
import {Container, Row, Col, Button} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Card from './card'
import Global from "../Global.json";

export default class StudentBooks extends Component {
    constructor(props) {
        super(props)
        // the initial application state
        this.state = {
            language: localStorage.getItem('language') ? localStorage.getItem('language') : "english",
            SliderLoad : false,
            responsive: {
                0: {
                    items: 2,
                },
                500: {
                    items: 2,
                },
                600: {
                    items: 2,
                },
                1000: {
                    items: 4,
                },
                1400: {
                    items: 3,
                },
                1600: {
                    items: 3,
                },
            }
          
        }
    }
    componentWillMount() {
        this.Categories();
        if (this.state.language !== "english") {
          this.setState({
              i18n: Global.ar
          });
      } else {
          this.setState({
              i18n: Global.en
          });
      }
      }

   
    
    render() {
        return (
         <div>
             <Row>
                 <Col lg={4} md={5} sm={12}>
                     <div className="horizontal-background">
                         <a href="/">
                            <div className="radwan-logo">
                                <img src="img/radwanLogo.png" class="img-fluid"/>
                            </div>
                         </a>
                         <div className="buttons">
                             <ul className="">
                                 <li>
                                    <a href="/studentbook" variant="outline-primary" className="book-buttons unselect-button">
                                        
                                        Book
                                    </a>
                                 </li>
                                 <li>
                                    <a  href="/studentresource"  className="book-buttons">Rescorces</a>
                                 </li>
                             </ul>
                         
                             
                         </div>
                     </div>
                 </Col>
                 <Col lg={8} md={7} sm={12}>
                  <Container>
                  <div className="main-holder">
                       <Row>
                       <OwlCarousel
                       responsive={this.state.responsive}
                            className="owl-theme"
                            loop
                            margin={10}
                            nav={false}
                            dots ={false}
                        >
                        <div class="item">
                            <Card />
                            <Card />
                        </div>
                        <div class="item">
                            <Card />
                            <Card />
                        </div>
                        <div class="item">
                            <Card />
                            <Card />
                        </div>
                        <div class="item">
                            <Card />
                            <Card />
                        </div>
                       
                        </OwlCarousel>
                       </Row>
                     </div>
                  </Container>
                 </Col>
             </Row>
        </div>
        );
    }
}  
