import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./header";
import Footer from "./footer";
import Global from "../Global.json"; 



export default class Supervisor extends Component {
  constructor(props) {
    super(props);
    // the initial application state
    this.state = {
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "english",
      Type: props.match.params.Type,
      Code: localStorage.getItem("Code")? localStorage.getItem("Code"): 0,
      userCode: 0,
      Erorr: false,
    };
  }

  componentWillMount() {
 
    if (this.state.language !== "english") {
      this.setState({
          i18n: Global.ar
      });
  } else {
      this.setState({
          i18n: Global.en
      });
  }
  }

  Login() {
    /** Login Start  */
    fetch(Global.api_link + "userLogin", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        code: this.state.userCode,
        type: this.state.Type,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.data == false) {
          this.setState({
            Erorr: true,
          });
        } else {
            localStorage.setItem("Code" , result.data)
            localStorage.setItem("Type" , this.state.Type)
             window.location.href="/subject" 
        }
 
      });
    /** Login End */
  }

  render() {
    return (
      <div>
        <div className="home-bacground">
          <Container>
            <Header />
            <div className="main-holder main-holder-Home">
              <div className="supervisor-code white-background card-holder">
                <div className="supervisor-holder">
                  <h5>{this.state.i18n.enterCode}</h5>
                  <form>
                    <div class="form-group">
                      <input
                        type="number"
                        name="code"
                        class="form-control input-field"
                        placeholder={this.state.i18n.EnterYourCode}       
                           onChange={(code) =>
                          this.setState({ userCode: code.target.value })
                        }
                      />
                    </div>
                  </form>

                  <button
                    className="next-button"
                    onClick={() => this.Login()}
                  >
                    {this.state.i18n.Next}
                  </button>

                  {this.state.Erorr  ? (
                    <h2 className="ErrorMsg">The Code Dose Not Found</h2>
                  ) : null}
                </div>
              </div>
            </div>
          </Container>
        </div>
        <Footer />
      </div>
    );
  }
}
