import React, { Component, PropTypes } from "react";
import {Container, Row, Col, NavDropdown } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Global from "../Global.json";



export default class Header extends Component {
    constructor(props) {
        super(props)
        // the initial application state
        this.state = {
            language: localStorage.getItem('language') ? localStorage.getItem('language') : "english",
         
        }
    }
    componentWillMount() {
        
        if (this.state.language !== "english") {
          this.setState({
              i18n: Global.ar
          });
      } else {
          this.setState({
              i18n: Global.en
          });
      }
      }
   
  ChangeEn() {
    if (this.state.language == "english") {
        localStorage.removeItem('language')
        localStorage.setItem('language', "arabic")
        window.location.reload()
    } else {
        localStorage.removeItem('language')
        localStorage.setItem('language', "english")
        window.location.reload()
    }

}
    
    render() {
        return (
         <div>
              <a href="/" >
                 <div className="logo">
                     <img src="/img/logo.png" class="img-fluid" />
                 </div>
                 </a> 
        </div>
        );
    }
}  
