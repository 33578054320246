import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Card from "./card";
import Footer from "./footer";
import Global from "../Global.json";

export default class ResourcesCategories extends Component {
  constructor(props) {
    super(props);
    // the initial application state
    this.state = {
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "english",
      SliderLoad: false,
      Code: localStorage.getItem("Code") ? localStorage.getItem("Code") : 0,
      Bookid: localStorage.getItem("Bookid")
        ? localStorage.getItem("Bookid")
        : 0,
      Type: localStorage.getItem("Type") ? localStorage.getItem("Type") : 0,
      cat_id: localStorage.getItem("cat_id")  ? localStorage.getItem("cat_id") : 0,
      Resources: [],
      Catogories: [],
      isloading:true,
      responsive: {
        0: {
          items: 2,
        },
        500: {
          items: 2,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        },
        1400: {
          items: 3,
        },
        1600: {
          items: 3,
        },
      },
      isloading: true,
    };
  }

  componentWillMount() {


    if (this.state.language !== "english") {
      this.setState({
          i18n: Global.ar
      });
  } else {
      this.setState({
          i18n: Global.en
      });
  }

  this.Categories();
  }

  ChangeEn() {
    if (this.state.language == "english") {
        localStorage.removeItem('language')
        localStorage.setItem('language', "arabic")
        window.location.reload()
    } else {
        localStorage.removeItem('language')
        localStorage.setItem('language', "english")
        window.location.reload()
    }

}
  Categories() {
    /** Resource Start  */
    fetch(Global.api_link + "getResourceCategories", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        book_id: this.state.Bookid,
        type_id : this.state.Type
      }),
    })
      .then((response) => response.json())
      .then((result) => { 
        if (result.Categories.length > 1) {
          var array = [];
          for (let step = 0; step < result.Categories.length; step += 2) {
            array.push({
              first: result.Categories[step],
              second: result.Categories[step + 1] ? result.Categories[step + 1] : null,
            });
          }
          this.setState({
            Catogories: array,
            isloading: false,
          });
        } else if (result.Categories.length == 1) {
          localStorage.setItem('cat_id', result.Categories[0].id)
          window.location.href = "/resource";
        }else {
          this.setState({
            Catogories: [],
            isloading: false,
          });
        } 
      });
    /** Resource End */
  }

  Logout() {
    localStorage.removeItem("Code", 0)
    localStorage.removeItem("Bookid", 0)
    localStorage.removeItem("Subject_id", 0)
    localStorage.removeItem("Type", 0)
    localStorage.removeItem("cat_id", 0)
    localStorage.removeItem("classeschild", false)
    localStorage.removeItem("subjectchild", false)
    window.location.href = "/"
  }


  render() {
    if (this.state.isloading) {
      return (

        <div class="loader">
          <div>
            <ul>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
            </ul>
          </div><span>{this.state.i18n.Loading}</span>
        </div>
      )
    } else {
      return (
        <div>
          <Row>
            <Col lg={4} md={5} sm={12} className="none_pad ">
              <div className="horizontal-background">
                <div className="radwan-logo">
                  <a href="/">
                    <img src="img/radwanLogo.png" className="img-fluid" />
                  </a>
                </div>
                <div className="buttons margin-tablet">
                  <ul className="">
                    <li>
                      <a
                        href="/book"
                        variant="outline-primary"
                        className="no-active"
                      >
                        {this.state.i18n.Book}
                      </a>
                    </li>
                    <li>
                      <a href="/ResourcesCategories" className="activated">
                      {this.state.i18n.Resources}
                      </a>
                    </li> 
                    <li>
                      <a href="/" className="no-active" onClick={() => this.Logout()}>
                      {this.state.i18n.Logout}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={8} md={7} sm={12} className="no_pad">
              <div className="resource-holder">
                <Row className="w-100" style={{ margin: 0, padding: 10 }}>
                  <OwlCarousel
                    responsive={this.state.responsive}
                    className="owl-theme"
                    loop={false}
                    margin={10}
                    nav={true} 
                    dots={false}
                  >
                    {this.state.Catogories.length > 0
                      ? this.state.Catogories.map((Cat, index) => {
                        return (
                          <div key={index} class="item">
                            <Card
                              name={Cat.first.name}
                              id={Cat.first.id}
                              image={Cat.first.image}
                              onclick={
                                () => {
                                  localStorage.setItem("cat_id", Cat.first.id);
                                  window.location.href = "/resource";
                                }
                              }
                            />
                            {Cat.second ? (
                              <Card
                                name={Cat.second.name}
                                id={Cat.second.id}
                                image={Cat.second.image}
                                onclick={
                                  () => {
                                    localStorage.setItem("cat_id", Cat.second.id);
                                    window.location.href = "/resource";
                                  }
                                }
                              />
                            ) : null}
                          </div>
                        );
                      })
                      : null}
                  </OwlCarousel>
                </Row>
              </div>
            </Col>
          </Row>
          
        </div>
      );
    }
  }
}
