import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Global from "../Global.json";
import Footer from './footer'
export default class Resource extends Component {
  constructor(props) {
    super(props);
    // the initial application state
    this.state = {
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "english",
      SliderLoad: false,
      Code: localStorage.getItem("Code") ? localStorage.getItem("Code") : 0,
      Bookid: localStorage.getItem("Bookid")
        ? localStorage.getItem("Bookid")
        : 0,
      Type: localStorage.getItem("Type") ? localStorage.getItem("Type") : 0,
      subjectchild: localStorage.getItem("subjectchild") ? localStorage.getItem("subjectchild") : false,
      classeschild: localStorage.getItem("classeschild") ? localStorage.getItem("classeschild") : false,
      cat_id: localStorage.getItem("cat_id") ? localStorage.getItem("cat_id") : 0,
      Resources: [],
      Catogories: [],
      isloading: true,
      interactive_cover: null,
      responsive: {
        0: {
          items: 2,
        },
        500: {
          items: 2,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        },
        1400: {
          items: 3,
        },
        1600: {
          items: 3,
        },
      },
      Media: [],
      isloading: true,
      ShowVideo: true,
      loadAudio:true
    };
  }

  componentWillMount() {
    this.Categories();
    if (this.state.language !== "english") {
      this.setState({
        i18n: Global.ar
      });
    } else {
      this.setState({
        i18n: Global.en
      });
    }

    /** Media Start  */
    fetch(Global.api_link + "getSocialMedia", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
      }),
    })
      .then((response) => response.json())
      .then((result) => {

        this.setState({
          Media: result.media,
        });

      });
    /** Media End */

  }


  ChangeEn() {
    if (this.state.language == "english") {
      localStorage.removeItem('language')
      localStorage.setItem('language', "arabic")
      window.location.reload()
    } else {
      localStorage.removeItem('language')
      localStorage.setItem('language', "english")
      window.location.reload()
    }

  }

  Categories() {
    /** Categories Start  */
    fetch(Global.api_link + "getResourceCategories", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        book_id: this.state.Bookid,
        type_id: this.state.Type
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Catogories: result.Categories,
        });
        this.Resource();
      });
    /** Categories End */
  }

  Resource() {
    /** Resource Start  */
    fetch(Global.api_link + "getResources", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        cat_id: this.state.cat_id,
        book_id: this.state.Bookid,
        type_id: this.state.Type,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.resources.length > 1) {
          this.setState({
            Resources: result.resources,
            interactive_cover: result.resources ? result.resources[0].interactive_cover : null,
            Link: result.resources ? result.resources[0].upload_link : null,
            isloading: false,
          });
        } else {
          this.setState({
            Resources: result.resources,
            isloading: false,
          });
        }

      });
    /** Resource End */
  }
  Logout() {
    localStorage.removeItem("Code", 0)
    localStorage.removeItem("Bookid", 0)
    localStorage.removeItem("Subject_id", 0)
    localStorage.removeItem("Type", 0)
    localStorage.removeItem("cat_id", 0)
    localStorage.removeItem("classeschild", false)
    localStorage.removeItem("subjectchild", false)
    window.location.href = "/"
  }
  render() {
    if (this.state.isloading) {
      return (

        <div class="loader">
          <div>
            <ul>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
            </ul>
          </div><span>{this.state.i18n.Loading}</span>
        </div>
      )
    } else {
      return (
        <div>
          <Row>
            <Col lg={4} md={5} sm={12}>
              <div className="horizontal-background horizontal-list-page">

                <div className="radwan-logo">
                  <a href="/">
                    <img src="img/radwanLogo.png" className="img-fluid" />
                  </a>
                </div>
                <div className="back-book-button">
                  <div>
                    <ul className="d-flex p-0"> 
                      <li><a
                        href="/book"
                        variant="outline-primary"
                      >
                        <img alt="Back" src="/img/back.png" height="40px" />
                      </a></li>
                    </ul>
                  </div>


                </div>
                <div className="list">
                  <ul className="">
                    {this.state.subjectchild ?
                      <li className="lang">
                        <a href="/subject" className="no-active" >
                          {this.state.i18n.Subjects}
                        </a>
                      </li>
                      :
                      null
                    }
                    {this.state.classeschild ?
                      <li className="lang">
                        <a href="/classes" className="no-active">
                          {this.state.i18n.Class}
                        </a>
                      </li>
                      :
                      null
                    }

                    {this.state.Catogories.length > 0
                      ? this.state.Catogories.map((Cat, index) => {
                        return (
                          <li
                            onClick={() => {
                              localStorage.setItem("cat_id", Cat.id);
                              window.location.href = "/resource";
                            }
                            }
                          >
                            <a
                              href="#"
                              className={
                                this.state.cat_id == Cat.id ? "active" : "inactive"
                              }
                            >
                              {this.state.cat_id == Cat.id ? (
                                <i class="fa fa-caret-right"></i>
                              ) : null}
                              <span>{Cat.name}</span>
                            </a>
                          </li>
                        );
                      })
                      : null}

                    <li className="lang">
                      <a className="inactive" onClick={() => this.Logout()}>
                        {this.state.i18n.Logout}
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={8} md={7} sm={12}>
              <Container>
                {this.state.Resources.length > 0
                  ?
                  <div className="white-background workSheet">
                    <Row>
                      {
                        this.state.ShowVideo ?
                          this.state.cat_id == 7 ?
                            <video src={Global.image_link + this.state.Link} controls width="70%" height="70%" className="Video"></video>
                            :
                            this.state.cat_id == 3 ?
                              <div className="Video">
                                <img alt="Cover" src={Global.image_link + this.state.interactive_cover} width="129px" height="auto" /> <br /><br />
                                {
                                  this.state.loadAudio ?
                                  <audio controls >
                                  <source src={Global.image_link + this.state.Link} type="audio/ogg" />
                                  <source src={Global.image_link + this.state.Link} type="audio/mpeg" />
                                  Your browser does not support the audio tag.
                             </audio>
                                  :
                                  null
                                }
                               
                              </div>
                              :
                              null
                          : null
                      }

                    </Row>

                    <Row>

                      {this.state.Resources.map((Cat, index) => {
                        return (

                          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            {this.state.cat_id == 3 || this.state.cat_id == 7 ?
                              <a onClick={() => {
                                this.setState({
                                  loadAudio: false
                                }, () => {
                                  setTimeout(()=>{
                                    this.setState({ Link: Cat.upload_link, interactive_cover: Cat.interactive_cover, loadAudio: true })
                                  },10)
                                })
                              }}>
                                <div className="file-holder">

                                  <div className="Box">
                                    <div className="image-folder">
                                      <img src={Global.image_link + Cat.image} />
                                    </div>
                                    <div className="BoxName">
                                      <span>{Cat.name}</span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                              :
                              <a href={Cat.link ? Cat.link : Global.image_link + Cat.upload_link} target="_blank" download>
                                <div className="file-holder">
                                  <div className="Box">
                                    <div className="image-folder">
                                      <img src={Global.image_link + Cat.image} />
                                    </div>
                                    <div className="BoxName">
                                      <span>{Cat.name}</span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            }

                          </Col>
                        );
                      })
                      }
                    </Row>
                  </div>
                  :
                  <>
                    <div className="NoBook NoResources">
                      <img alt="No Book" src="/img/nobook.jpg" width="auto" height="119px" />
                      <h4>{this.state.i18n.NoResources} </h4>
                    </div>

                  </>}
              </Container>
            </Col>
          </Row>

        </div >
      );
    }
  }
}
