import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import './App.css';
import Home from "./pages/home"
import Supervisor from "./pages/supervisor-code"
import StudentBooks from "./pages/studentbook"
import Resource from "./pages/resource"
import Book from "./pages/book"
import AllBook from "./pages/AllBook"
import ResourcesCategories from "./pages/ResourcesCategories"
import Subject from "./pages/subject"
import Classes from "./pages/classes" 


function App() {
  return (
    /** Router Start */
    <BrowserRouter basename="/">
      <div>
        <div>
          <Route path="/" component={Home} exact />
          <Route path="/supervisor-code/:Type" component={Supervisor} exact />
          <Route path="/studentbook" component={StudentBooks} exact />
          <Route path="/resource" component={Resource} exact />
          <Route path="/ResourcesCategories" component={ResourcesCategories} exact />
          <Route path="/book" component={Book} exact />
          <Route path="/AllBook" component={AllBook} exact />
          <Route path="/subject" component={Subject} exact />
          <Route path="/classes" component={Classes} exact /> 
        </div>
      </div>

    </BrowserRouter>
    /** Router End */
  );
}

export default App;
