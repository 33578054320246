import React, { Component } from "react"; 
import 'bootstrap/dist/css/bootstrap.min.css';
import Global from "../Global.json";


export default class Footer extends Component {
    constructor(props) {
        super(props)
        // the initial application state
        this.state = {
            language: localStorage.getItem('language') ? localStorage.getItem('language') : "english",
            Media: []

        }
    }


    componentWillMount() {

        if (this.state.language !== "english") {
            this.setState({
                i18n: Global.ar
            });
        } else {
            this.setState({
                i18n: Global.en
            });
        }





        /** Media Start  */
        fetch(Global.api_link + "getSocialMedia", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {

                this.setState({
                    Media: result.media,
                });

            });
        /** Media End */
    }

    ChangeEn() {
        if (this.state.language == "english") {
            localStorage.removeItem('language')
            localStorage.setItem('language', "arabic")
            window.location.reload()
        } else {
            localStorage.removeItem('language')
            localStorage.setItem('language', "english")
            window.location.reload()
        }

    }

    render() {
        return (
            <div className="footer">
                <ul className="d-flex p-0">

                    <li>
                        <a className="LangFoot" onClick={() => this.ChangeEn()}>
                            {this.state.i18n.Lang}
                        </a>
                    </li>
                    {this.state.Media.map(acc => {
                        return (
                            <li>
                                <a href={acc.link}>
                                    <i class={"fa " + acc.icon} aria-hidden="true"></i>
                                </a>
                            </li>

                        )
                    })}
                </ul>
            </div>
        );
    }
}  
