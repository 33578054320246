import React, { Component, PropTypes } from "react";
import { Container, Row, Col, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./header";
import Global from "../Global.json";

export default class Card extends Component {
  constructor(props) {
    super(props);
    // the initial application state
    this.state = {
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "english",
        Name : this.props.name
    };
  }

  componentWillMount () {
    console.log(this.props.name)
  }
  render() {
    return (
      <div>
        <a href={this.props.download ? this.props.download : "#"} onClick={this.props.onclick} key={this.props.id} target={this.props.download ? "_blank" : "_self"}>
          <div className="userType white-background card-holder resource-card">
            <div className="userImage text-center">
              <img alt="" src={Global.image_link + this.props.image} class="img-fluid"  />
            </div>
            <div className="text-center userType-name">
              <span>{this.props.name}</span>
            </div>
          </div>
        </a>
      </div>
    );
  }
}
