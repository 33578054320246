import React, { Component } from "react"; 
import { Container, Row, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./header"
import Footer from "./footer"
import Global from "../Global.json";

export default class Home extends Component {
    constructor(props) {
        super(props)
        // the initial application state
        this.state = {
            language: localStorage.getItem('language') ? localStorage.getItem('language') : "english",
         
        }
    }

    componentWillMount() {
        if (this.state.language !== "english") {
          this.setState({
              i18n: Global.ar
          });
      } else {
          this.setState({
              i18n: Global.en
          });
      }
      }
    
    render() {
        return (
         <div>
             <div className="home-bacground">
             
             <Container>
                 <Header />
                 <div className="main-holder">
                    <Row>
                        <Col lg={7} md={12} sm={12}  className="mr-auto ml-auto">
                           <Row>
                               <Col lg={6} md={6} sm={6} xs={12}>
                                   <a href="/supervisor-code/1" params="1">
                                    <div className="userType white-background card-holder home-cards">
                                        <div className="userImage text-center">
                                            <img src="img/student.png" class="img-fluid" alt="Student" />
                                        </div>
                                        <div className="text-center userType-name">
                                            <span>{this.state.i18n.student}</span>
                                        </div>
                                    </div>
                                   </a>
                               </Col>
                               <Col lg={6} md={6} sm={6} xs={12}>
                                   <a href="/supervisor-code/2" params="2" >
                                    <div className="userType white-background card-holder home-cards">
                                        <div className="userImage text-center">
                                            <img src="img/supervisor.png" class="img-fluid" alt="Student" />
                                        </div>
                                        <div className="text-center userType-name">
                                             <span>{this.state.i18n.supervisor}</span>
                                        </div>
                                    </div>
                                   </a>
                               </Col>
                           </Row>
                        </Col>
                    </Row>
                 </div>
             </Container>
             
             </div>
             <Footer />
        </div>
        );
    }
}  
