import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./footer";
import Global from "../Global.json";

export default class Subject extends Component {
  constructor(props) {
    super(props);
    // the initial application state
    this.state = {
      language: localStorage.getItem("language") ? localStorage.getItem("language") : "english",
      SliderLoad: false,
      Code: localStorage.getItem("Code") ? localStorage.getItem("Code") : 0,
      Subject_id: localStorage.getItem("Subject_id") ? localStorage.getItem("Subject_id") : 0,
      subjects: [],
      subjectchild: localStorage.getItem("subjectchild") ? localStorage.getItem("subjectchild") : false,
      isloading: true
    };
  }

  componentWillMount() {
    if (this.state.language !== "english") {
      this.setState({
        i18n: Global.ar
      });
    } else {
      this.setState({
        i18n: Global.en
      });
    }
    this.Subjects()
    this.GroupLogo()
  }

  Subjects() {
    /** Subjects Start  */
    fetch(Global.api_link + "getSubjects", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        code: this.state.Code,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.Subjects.length == 1) {
          localStorage.setItem("Subject_id", + result.Subjects[0].id)
          window.location.href = "/classes"
        } else {
          this.setState({
            subjects: result.Subjects,
          });
          localStorage.setItem("subjectchild", true)
        }
      });
    /** Subjects End */
  }


  ChangeEn() {
    if (this.state.language == "english") {
      localStorage.removeItem('language')
      localStorage.setItem('language', "arabic")
      window.location.reload()
    } else {
      localStorage.removeItem('language')
      localStorage.setItem('language', "english")
      window.location.reload()
    }

  }


  GroupLogo() {

    /** Book Start  */
    fetch(Global.api_link + "getCodeGroupLogo", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        code: this.state.Code,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        this.setState({
          Logo: result.data,
          isloading: false,
        });
      });
    /** Book End */
  }

  Logout() {
    localStorage.removeItem("Code", 0)
    localStorage.removeItem("Bookid", 0)
    localStorage.removeItem("Subject_id", 0)
    localStorage.removeItem("Type", 0)
    localStorage.removeItem("cat_id", 0)
    localStorage.removeItem("classeschild", false)
    localStorage.removeItem("subjectchild", false)
    window.location.href = "/"
  }
  render() {

    if (this.state.isloading) {
      return (

        <div class="loader">
          <div>
            <ul>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
            </ul>
          </div><span>{this.state.i18n.Loading}</span>
        </div>
      )
    } else {
      return (
        <div>
          <Row>
            <Col lg={4} md={5} sm={12}>
              <div className="horizontal-background horizontal-list-page">
                <div className="radwan-logo">
                  <a href="/">
                    <img src="img/radwanLogo.png" className="img-fluid" />

                  </a>
                </div>
                <div className="back-book-button">
                  <Footer />
                </div>
                <div className="list">
                  <div className="buttons book-buttons-head">
                    <ul className="book-header-buttons SideButtons">

                      <li>
                        <a className="no-active" onClick={() => this.Logout()}>
                          {this.state.i18n.Logout}
                        </a>
                      </li>
                    </ul>
                  </div>

                  {
                    this.state.Logo ?
                      <div className="LogoGroup">
                        <img alt="" src={Global.image_link + this.state.Logo} width="40px" />
                      </div>
                      :
                      null
                  }


                </div>
              </div>
            </Col>
            <Col lg={8} md={7} sm={12}>
              <Container>
                <div className="white-background workSheet">
                  <Row>
                    {this.state.subjects.map((Sub) => {
                      return (
                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                          <a onClick={() => {
                            localStorage.setItem("Subject_id", Sub.id);
                            window.location.href = "/classes";
                          }}>
                            <div className="file-holder">
                              <div className="Box">
                                <div className="image-folder">
                                  <img src={Sub.image !== null ? Global.image_link + Sub.image : "/img/book.png"} />
                                </div>
                                <div className="BoxName">
                                  <span>{Sub.name}</span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </Col>
                      )
                    })}
                  </Row>
                </div>
              </Container>
            </Col>
          </Row>
        </div>
      );
    }
  }
}
