import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Global from "../Global.json";
import Footer from "./footer";
export default class Book extends Component {
  constructor(props) {
    super(props);
    // the initial application state
    this.state = {
      language: localStorage.getItem("language") ? localStorage.getItem("language") : "english",
      Code: localStorage.getItem("Code") ? localStorage.getItem("Code") : 0,
      Bookid: localStorage.getItem("Bookid") ? localStorage.getItem("Bookid") : 0,
      Pages: [],
      isloading: true,
      cover: {},
      subjectchild: localStorage.getItem("subjectchild") ? localStorage.getItem("subjectchild") : false,
      classeschild: localStorage.getItem("classeschild") ? localStorage.getItem("classeschild") : false,
      Class_id: localStorage.getItem("Class_id") ? localStorage.getItem("Class_id") : 0,
      Subject_id: localStorage.getItem("Subject_id") ? localStorage.getItem("Subject_id") : 0,
      isFull: false

    };
  }

  componentWillMount() {

    if (this.state.language !== "english") {
      this.setState({
        i18n: Global.ar
      });
    } else {
      this.setState({
        i18n: Global.en
      });
    }



    if (this.state.Subject_id == 0 || this.state.Class_id == 0) {
      localStorage.removeItem("Code", 0)
      localStorage.removeItem("Bookid", 0)
      localStorage.removeItem("Subject_id", 0)
      localStorage.removeItem("Type", 0)
      localStorage.removeItem("cat_id", 0)
      localStorage.removeItem("classeschild", false)
      localStorage.removeItem("subjectchild", false)
      window.location.href = "/"
    } else {
      this.Book();
      this.GroupLogo()
    }

  }



  Book() {

    /** Book Start  */
    fetch(Global.api_link + "getBook", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        code: this.state.Code,
        lang: this.state.language,
        book_id: this.state.Bookid
      }),
    })
      .then((response) => response.json())
      .then((result) => {

        if (result.pages.length > 0) {
          // localStorage.setItem("Bookid", result.data.id);
          let cover = "";
          let pages = [];
          if (result.pages.length > 0) {
            cover = result.pages[0];
            pages = result.pages;//result.pages.shift();
          }
          this.setState({
            Pages: pages,
            cover: cover,
            isloading: false,
          });
        } else {
          this.setState({
            isloading: false,
          });
        }

      }).catch(error => {
        this.setState({

          isloading: false,
        });
      });
    /** Book End */
  }


  GroupLogo() {

    /** Book Start  */
    fetch(Global.api_link + "getCodeGroupLogo", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        code: this.state.Code,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Logo: result.data,
          isloading: false,
        });
      });
    /** Book End */
  }

  Logout() {
    localStorage.removeItem("Code", 0)
    localStorage.removeItem("Bookid", 0)
    localStorage.removeItem("Subject_id", 0)
    localStorage.removeItem("Type", 0)
    localStorage.removeItem("cat_id", 0)
    localStorage.removeItem("classeschild", false)
    localStorage.removeItem("subjectchild", false)
    window.location.href = "/"
  }
  render() {

    if (this.state.isloading) {
      return (

        <div class="loader">
          <div>
            <ul>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
              <li>
                <svg viewBox="0 0 90 120" fill="currentColor">
                  <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                </svg>
              </li>
            </ul>
          </div><span>{this.state.i18n.Loading}</span>
        </div>
      )
    } else {
      return (
        <div>
          <Row>
            <div className="book-header-h">
              <div className="radwan-logo book-header-logo">
                <a href="/">
                  <img src="img/radwanLogo.png" className="img-fluid" />
                </a>
              </div>
  
              <div className="buttons book-buttons-head">
                <ul className="book-header-buttons">

                  {this.state.subjectchild ?
                    <li>
                      <a href="/subject" className="no-active" >
                        {this.state.i18n.Subjects}
                      </a>
                    </li>
                    :
                    null
                  }
                  {this.state.classeschild ?
                    <li >
                      <a href="/classes" className="no-active">
                        {this.state.i18n.Class}
                      </a>
                    </li>
                    :
                    null
                  }

                  <li>
                    <a
                      href="/book"
                      variant="outline-primary"
                      className="activated"
                    >
                      {this.state.i18n.Book}
                    </a>
                  </li>
                  <li>
                    <a href="/ResourcesCategories" className="no-active">
                      {this.state.i18n.Resources}
                    </a>
                  </li>

                  <li>
                    <a className="no-active" onClick={() => this.Logout()}>
                      {this.state.i18n.Logout}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <Col xl={4} lg={4} md={5} sm={12} className="book-header-mobile">
              <div className="book-header">
                <div className="radwan-logo">
                  <a href="/">
                    <img src="img/radwanLogo.png" className="img-fluid" />
                  </a>
                </div>
                <div className="buttons BookBut">
                  <ul className="">

                    {this.state.subjectchild ?
                      <li>
                        <a href="/subject" className="no-active" >
                          {this.state.i18n.Subjects}
                        </a>
                      </li>
                      :
                      null
                    }
                    {this.state.classeschild ?
                      <li >
                        <a href="/classes" className="no-active">
                          {this.state.i18n.Class}
                        </a>
                      </li>
                      :
                      null
                    }


                    <li>
                      <a
                        href="/book"
                        variant="outline-primary"
                        className="activated"
                      >
                        {this.state.i18n.Book}
                      </a>
                    </li>
                    <li>
                      <a href="/ResourcesCategories" className="no-active">
                        {this.state.i18n.Resources}
                      </a>
                    </li>


                    <li>
                      <a className="no-active" onClick={() => this.Logout()}>
                        {this.state.i18n.Logout}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="LogoGroup">
                  <img alt="" src={Global.image_link + this.state.Logo} />
                </div>
              </div>
            </Col>
            <Col xl={8} lg={8} md={7} sm={12} className="book-column">
              <Container>

                {this.state.Pages.length > 0 ?
                  <Row style={{ margin: 0, padding: 10 }}>
                    <button onClick={() => {
                      this.setState({
                        isFull: true
                      })
                    }}>{this.state.i18n.FullScreen}</button>
                  </Row>
                  : null}

                <div className="">
                  <Row style={{ margin: 0, padding: 10 }}>
                    <>
                      {this.state.Pages.length > 0 ?
                        <iframe src={"https://cp.redwanconnect.com/BookView?code=" + this.state.Code + "&lang=" + this.state.language + "&book_id=" + this.state.Bookid} title="description" width={"100%"} frameBorder={0} className="IframeBook" />
                        :
                        <>
                          <div className="NoBook">
                            <img alt="No Book" src="/img/nobook.jpg" width="auto" height="119px" />
                            <h4>{this.state.i18n.NoBook} </h4>
                          </div>
                        </>
                      }
                    </>
                  </Row>
                </div>
              </Container>
            </Col>

            {
              this.state.isFull ?

                <div style={{ position: "absolute", top: 0, left: 0, background: "#fff", width: "100%", height: "100%", zIndex: 999 }}>
                  <Row style={{ position: "absolute", top: 20, left: 50 }}>
                    <button onClick={() => {
                      this.setState({
                        isFull: false
                      })
                    }}>{this.state.i18n.Back}</button>
                  </Row>

                  <iframe src={"https://cp.redwanconnect.com/BookView?code=" + this.state.Code + "&lang=" + this.state.language + "&book_id=" + this.state.Bookid} title="description" width={"100%"} frameBorder={0} className="IframeBook IframeBookFull" />

                </div>
                :
                null
            }


          </Row>
        </div>
      );
    }
  }
}
