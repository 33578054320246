import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./footer";
import Global from "../Global.json";

export default class AllBoks extends Component {
    constructor(props) {
        super(props);
        // the initial application state
        this.state = {
            language: localStorage.getItem("language") ? localStorage.getItem("language") : "english",
            SliderLoad: false,
            Code: localStorage.getItem("Code") ? localStorage.getItem("Code") : 0,
            Subject_id: localStorage.getItem("Subject_id") ? localStorage.getItem("Subject_id") : 0,
            Class_id: localStorage.getItem("Class_id") ? localStorage.getItem("Class_id") : 0,
            subjectchild: localStorage.getItem("subjectchild") ? localStorage.getItem("subjectchild") : false,
            classeschild: localStorage.getItem("classeschild") ? localStorage.getItem("classeschild") : false,
            isloading: true,
            Books: []
        };
    }

    componentWillMount() {
        if (this.state.language !== "english") {
            this.setState({
                i18n: Global.ar
            });
        } else {
            this.setState({
                i18n: Global.en
            });
        }
        this.AllBooks()
    }

    /*** Functions  */

    AllBooks() {
        /** AllBooks Start  */
        fetch(Global.api_link + "getBooks", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                lang: this.state.language,
                subject_id: this.state.Subject_id,
                code: this.state.Code,
                class_id: this.state.Class_id,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.data.length == 1) {
                    localStorage.setItem("Bookid", result.data[0].id)
                    window.location.href = "/book"
                } else {

                    this.setState({
                        Books: result.data,
                        isloading: false
                    });
                }
            });
        /** AllBooks End */
    }

    /** Language Start */
    ChangeEn() {
        if (this.state.language == "english") {
            localStorage.removeItem('language')
            localStorage.setItem('language', "arabic")
            window.location.reload()
        } else {
            localStorage.removeItem('language')
            localStorage.setItem('language', "english")
            window.location.reload()
        }
    }

    /** Language End */


    /** Logout  */
    Logout() {
        localStorage.removeItem("Code", 0)
        localStorage.removeItem("Bookid", 0)
        localStorage.removeItem("Subject_id", 0)
        localStorage.removeItem("Type", 0)
        localStorage.removeItem("cat_id", 0)
        localStorage.removeItem("classeschild", false)
        localStorage.removeItem("subjectchild", false)
        window.location.href = "/"
    }
    /** Logout End  */


    render() {

        if (this.state.isloading) {
            return (

                <div className="loader"> {/** Loader */}
                    <div>
                        <ul>
                            <li>
                                <svg viewBox="0 0 90 120" fill="currentColor">
                                    <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                                </svg>
                            </li>
                            <li>
                                <svg viewBox="0 0 90 120" fill="currentColor">
                                    <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                                </svg>
                            </li>
                            <li>
                                <svg viewBox="0 0 90 120" fill="currentColor">
                                    <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                                </svg>
                            </li>
                            <li>
                                <svg viewBox="0 0 90 120" fill="currentColor">
                                    <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                                </svg>
                            </li>
                            <li>
                                <svg viewBox="0 0 90 120" fill="currentColor">
                                    <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                                </svg>
                            </li>
                            <li>
                                <svg viewBox="0 0 90 120" fill="currentColor">
                                    <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                                </svg>
                            </li>
                        </ul>
                    </div><span>{this.state.i18n.Loading}</span>
                </div> /** Loader */
            )
        } else {
            return (
                <div>
                    <Row>

                        <Col lg={4} md={5} sm={12}> {/** Side Menu */}
                            <div className="horizontal-background horizontal-list-page">
                                <div className="radwan-logo">
                                    <a href="/">
                                        <img src="img/radwanLogo.png" className="img-fluid" />

                                    </a>
                                </div>
                                <div className="back-book-button">
                                </div>
                                <div className="list">
                                    <div className="buttons book-buttons-head">
                                        <ul className="book-header-buttons SideButtons">

                                            {this.state.subjectchild ?
                                                <li>
                                                    <a href="/subject" className="no-active" >
                                                        {this.state.i18n.Subjects}
                                                    </a>
                                                </li>
                                                :
                                                null
                                            }
                                            {this.state.classeschild ?
                                                <li >
                                                    <a href="/classes" className="no-active">
                                                        {this.state.i18n.Class}
                                                    </a>
                                                </li>
                                                :
                                                null
                                            }

                                            <li>
                                                <a className="no-active" onClick={() => this.Logout()}>
                                                    {this.state.i18n.Logout}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Col> {/** Side Menu */}
                        <Col lg={8} md={7} sm={12}>
                            <Container>
                                {this.state.Books.length > 0 ?
                                    <div className="white-background workSheet"> {/** All Books  */}
                                        <Row>
                                            {
                                                this.state.Books.map((Book) => {
                                                    return (
                                                        <Col xl={3} lg={4} md={4} sm={6} xs={6}>
                                                            <div className="file-holder">
                                                                <a onClick={() => {
                                                                    localStorage.setItem("Bookid", Book.id);
                                                                    window.location.href = "/book";
                                                                }}>

                                                                    <div className="Box">
                                                                        <div className="image-folder">
                                                                            <img src={Book.interactive_cover !== null ? Global.image_link + Book.interactive_cover : "/img/book.png"} />
                                                                        </div>
                                                                        <div className="BoxName">
                                                                            <span>{Book.name}</span>
                                                                        </div>
                                                                    </div>

                                                                </a>
                                                            </div>
                                                        </Col>
                                                    )
                                                })}
                                        </Row>
                                    </div>  /** All Books End */
                                    :
                                    <>
                                        <Row> {/** IF  No Books  */}
                                            <div className="NoBook AllBooks">
                                                <img alt="No Book" src="/img/nobook.jpg" width="auto" height="119px" />
                                                <h4>{this.state.i18n.NoBook} </h4>
                                            </div>
                                        </Row> {/** If  No Books  */}

                                    </>
                                }
                            </Container>
                        </Col>
                    </Row>
                    <div className="AllBookFooter">
                        <Footer />
                    </div>
                    
                </div>
            );
        }
    }

}
